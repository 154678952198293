import _ from "lodash";
import { useState } from "react";
import styled from "styled-components";
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonItem, IonItemDivider, IonList } from "@ionic/react";

import { APInput, IGerdooPlayerCommand } from "gerdoo-api";
import { ControllerConnector } from "gerdoo-controller-connector";

import { getRandomInputValue } from "../../apps/TestConsole";
import { ControllerApp } from "../../apps/ControllerApp";

export const TestControllerArray: React.FC<{roomCode: string, controllers: ControllerConnector[]}> = ({roomCode, controllers}) => {
    const [nextActions, setNextActions] = useState<{ [sid: string]: any }>({});
    const [lastActions, setLastActions] = useState<{ [sid: string]: any }>({});
    const [cIndex, setcIndex] = useState(0);

    return <>
        <div className="toolbar">
            <IonButton onClick={() => {
                controllers.map((connector, i) => {
                    connector?.connect(`s${i}-${roomCode}`, roomCode);
                });
            }}>Join All</IonButton>
            <IonButton onClick={() => setNextActions(generateNextActions(controllers))}>Generate Actions</IonButton>

            <IonButton disabled={_.isEmpty(nextActions)} onClick={() => {
                submitActions(controllers, nextActions);
                setLastActions(nextActions);
                setNextActions({});
            }}>Submit</IonButton>

            <IonButton onClick={() => {
                const actions = generateNextActions(controllers);
                submitActions(controllers, actions);
                setLastActions(actions);
                setNextActions({});
            }}>{'Gen. & Submit'}</IonButton>

            <IonButton
                onClick={() => {
                    document.location = document.location.origin + '?join=' + roomCode
                }}
            >
                Refresh
            </IonButton>
        </div>

        <div>
            <div style={{width: 400, float: 'right'}}>
                <ControllerAppDiv>
                    { controllers[cIndex] && <ControllerApp connector={controllers[cIndex]} /> }
                </ControllerAppDiv>
            </div>
            <ControllersArrayDiv>
                {_.range(0, 6).map(i => controllers[i] && <ControllerCard controller={controllers[i]} select={() => setcIndex(i)} isSelected={i === cIndex} />)}
            </ControllersArrayDiv>
        </div>
    </>;
}

const ControllerCard: React.FC<{controller: ControllerConnector, select: () => void, isSelected: boolean}> = ({controller, select, isSelected}) => {
    const {
        actionPanel,
        me,
        room,
    } = controller.state;

    return <IonCard style={{width: 300}} className='ion-text-center' color={isSelected ? 'secondary' : ''}>
        <IonCardHeader>
            <h3>
                { room?.me?.name }
                [pid={ me?.pid }]
            </h3>
        </IonCardHeader>
        <IonCardContent>
            <IonList>
                <IonItemDivider>Action Panel</IonItemDivider>
                <IonItem>
                    <ul>{ _.keys(actionPanel.input).map(k => <li key={k}>{getInputSummary(k, actionPanel.input[k])}</li>) }</ul>
                </IonItem>
            </IonList>
            <br />
            <IonButton slot="end" onClick={() => select()}>View</IonButton>
        </IonCardContent>
        {/* <IonCardHeader>
            { !_.isEmpty(_.keys(actionPanel.input)) && <IonBadge color='danger'>!</IonBadge> }

        </IonCardHeader> */}
</IonCard>}

function getInputSummary(key: string, input: APInput) {
    return `${key}:${input.type}: ${JSON.stringify(input.value)}`;
}

const ControllersContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 90vh;
    justify-content: space-evenly;
    flex-wrap: wrap;
    overflow-y: scroll;
`;

const ControllerAppDiv = styled.div`
    border: thin solid white;
    position: relative;
    width: 100%;
    height: 700px;
    width: 400px;
    margin: 10px;
`;

const ControllersArrayDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    height: 90vh;
`;

function submitActions(controllers: ControllerConnector[], actions: { [sid: string]: Partial<IGerdooPlayerCommand> }) {
    for (const sid in actions) {
        const cntl = controllers.find(c => c.sessionId === sid);
        if (cntl) {
            cntl.sendCommand(actions[sid]);
        }
    }
}

function generateNextActions(controllers: ControllerConnector[]): { [sid: string]: Partial<IGerdooPlayerCommand> } {
    const result: { [sid: string]: Partial<IGerdooPlayerCommand> } = {};

    for (const controller of controllers) {
        const { gameData, actionPanel, me } = controller.state;

        for (const inputKey in actionPanel.input) {
            if (inputKey === 'concede') continue;

            const value = getRandomInputValue(me, gameData, actionPanel.input[inputKey]);
            if (!value) continue;

            result[controller.sessionId] = {
                type: 'roj.input',
                service: 'roj',
                inputKey,
                action: value[0] as any,
                data: value[1],
            };
        }
    }

    return result;
}
