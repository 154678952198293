import _ from "lodash";
import { useSelector } from "react-redux";
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonChip, IonCol, IonRow, IonText } from "@ionic/react";

import { ROJ } from "gerdoo-api";
import { IControllerState } from "gerdoo-controller-connector";
import { RojAvatar, RojImage } from "../RojAvatar";

export const InboxMissionCard: React.FC<{ round: ROJ.IRound, phase: ROJ.PhaseType }> = ({ round: missionRnd, phase }) => {
    const { gameState } = useSelector((state: IControllerState) => state);
    const isActiveMission = missionRnd.number === gameState.round.number && !missionRnd.isReported;

    return <IonRow>
        {missionRnd.secrecy > 1 && <IonCol size="12" className="ion-text-center">
            <IonCard>
                <IonCardHeader>
                    <IonCardTitle color='danger'>TOP SECRET</IonCardTitle>
                    <IonCardSubtitle>This mission needs 2 Leaks to fail.</IonCardSubtitle>
                </IonCardHeader>
            </IonCard>
        </IonCol>}

        {isActiveMission && <ActiveMissionRows round={missionRnd} phase={phase} />}
        {!isActiveMission && <ReportedMissionRows round={missionRnd} phase={phase} />}
    </IonRow>;
}

const ActiveMissionRows: React.FC<{ round: ROJ.IRound, phase: ROJ.PhaseType }> = ({ round, phase }) => {
    const { gameData, gameState } = useSelector((state: IControllerState) => state);
    const isActiveMission = round.number === gameState.round.number && !round.isReported;
    const leaderId = isActiveMission ? gameState.proposal?.leaderId : round.leaderId;
    const leader = _.values(gameData.players).find(p => p.id === leaderId);
    const squad = (isActiveMission ? (gameState.proposal?.squad || []) : round.squad || []).map(pid => _.values(gameData.players).find(p => p.id === pid));
    const isProposalApproved = gameState?.proposal?.isApproved;
    const missionStarted = ['mission', 'missionReport'].indexOf(phase) >= 0;

    return <>
        <IonCol size="12" className="ion-text-center">
            {phase === 'picking' && leader && <div className="ion-margin-top">
                <IonText><b><i><IonChip>{leader.name}</IonChip> is proposing a taskforce...</i></b></IonText>
            </div>}
            {leader && !_.isEmpty(squad) && <div className="ion-margin-top">
                { phase !== 'picking' && <IonText>Leader: <IonChip>{leader.name}</IonChip></IonText> }
                <div className="ion-margin-top" style={{ display: 'flex', justifyContent: 'center' }}>
                    {squad.map(p => <RojAvatar key={p?.id} caption={p?.name!} img={<RojImage type="player" id={p?.id} />} />)}
                </div>
            </div>}
            {phase === 'voting' && <>
                <div className="ion-margin-top">
                    <IonText><b><i>Everyone is voting on it...</i></b></IonText>
                </div>
            </>}
            {phase === 'votingResult' && <>
                <div className="ion-margin-top">
                    <IonCard>
                        <IonCardHeader color={isProposalApproved ? 'tertiary' : 'danger'}>
                            <IonCardTitle>{isProposalApproved ? 'TASKFORCE APPROVED' : 'TASKFORCE REJECTED'}</IonCardTitle>
                            <IonCardSubtitle>{isProposalApproved ? 'On their way to the mission...' : 'Puma loses a mega-donor and demands a new proposal.'}</IonCardSubtitle>
                        </IonCardHeader>
                    </IonCard>
                </div>
            </>}
            {missionStarted && !round.isReported && <div className="ion-margin-top">
                <IonText><b><i>Mission in progress...</i></b></IonText>
            </div>}
        </IonCol>
    </>;
}

const ReportedMissionRows: React.FC<{ round: ROJ.IRound, phase: ROJ.PhaseType }> = ({ round, phase }) => {
    const { gameData, gameState } = useSelector((state: IControllerState) => state);
    const leader = _.values(gameData.players).find(p => p.id === gameState.proposal?.leaderId);
    const squad = (round.squad || []).map(pid => _.values(gameData.players).find(p => p.id === pid));
    const leakCount = round.failPlayers?.length || 0;

    return <IonCol size="12" className="ion-text-center">
        {leader && <>
            <IonText>Leader: <IonChip>{leader.name}</IonChip></IonText>
        </>}
        {leader && !_.isEmpty(squad) && <div className="ion-margin-top">
            <div className="ion-margin-top" style={{ display: 'flex', justifyContent: 'center' }}>
                {squad.map(p => <RojAvatar key={p?.id} caption={p?.name!} img={<RojImage type="player" id={p?.id} />} />)}
            </div>
        </div>}
        {round.isReported && <IonCol size="12">
            <IonCard color={round.isLeaked ? 'danger' : 'tertiary'}>
                <IonCardHeader>
                    <IonCardTitle>{leakCount || 'NO'}{` LEAK${leakCount === 1 ? '' : 'S'}`}</IonCardTitle>
                    <IonCardSubtitle>{leakCount > round.secrecy && <IonText>Wreckless!</IonText>}</IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent className="ion-padding">
                    {leakCount > 0 && <IonText>
                        <i>At least {leakCount} Leaker(s) infiltrated this taskforce.</i>
                    </IonText>}
                    {leakCount === 0 && <IonText><i>No Leaks, but maybe leakers are laying low.</i></IonText>}
                </IonCardContent>
            </IonCard>
        </IonCol>}
    </IonCol>;
}
