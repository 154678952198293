import { Reducer, AnyAction } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRoom, IGerdooPlayer } from 'gerdoo-api';

export interface IControllerRoomState extends Partial<IRoom> {
    me: IGerdooPlayer;
    others: IGerdooPlayer[];
}

const initialState: IControllerRoomState = {
    state: 'lobby',
    me: undefined,
    others: [],
};

const roomSlice = createSlice({
    name: 'room',
    initialState,
    reducers: {
        reset: () => initialState,
        update: (state, action: PayloadAction<{data: Partial<IRoom>, me: IGerdooPlayer, others: IGerdooPlayer[]}>) => {
            const {data, me, others} = action.payload;
            
            return {
                ...state,
                ...data,
                others,
                me,
            }
        },
    },
    extraReducers: builder => {
    }
});

export const roomReducer: Reducer<IControllerRoomState, AnyAction> = roomSlice.reducer;
export const roomActions = {
    ...roomSlice.actions,
};
