import Axios from 'axios';
import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';

import { APInputDataType, GerdooAuthToken, IGerdooPlayerCommand, IGerdooPlayerUpdate, IRoomPlayerLeaveCommand, IPubSubProvider, ROJ } from 'gerdoo-api';
import { PubNubProvider } from 'gerdoo-pubsub';
import { waitUntil } from 'gerdoo-util';

import { actionPanelActions, actionPanelReducer, connectionActions, connectionReducer, directoryActions, directoryReducer, gameDataActions, gameDataReducer, gameStateActions, gameStateReducer, IConnectionState, IControllerActionPanel, IControllerNavState, IControllerRoomState, inboxActions, inboxReducer, meActions, meReducer, navActions, navReducer, roomActions, roomReducer } from './redux/reducers';

export interface IControllerState {
    connection: IConnectionState;
    room: IControllerRoomState;
    actionPanel: IControllerActionPanel;
    inbox: ROJ.IControllerInbox;
    directory: ROJ.IControllerDirectory;
    gameData: ROJ.IControllerGameData;
    gameState: ROJ.IControllerGameState;
    nav: IControllerNavState;
    me: ROJ.IPlayer;
};

export class ControllerConnector {
    pubsub: IPubSubProvider;
    reducer;
    store;
    prevState: IControllerState;
    private lastUnlockTime = 0;
    private lastUpdateTime = 0;
    private lastJoinSeq = 0;

    constructor(
        public readonly sessionId: string,
        private readonly authEndpoint: string,
        private readonly onStateChange: (state: IControllerState, prevState: IControllerState) => void,
        private readonly localTest: boolean,
    ) {
        this.reducer = combineReducers({
            room: roomReducer,
            gameData: gameDataReducer,
            gameState: gameStateReducer,
            connection: connectionReducer,
            actionPanel: actionPanelReducer,
            inbox: inboxReducer,
            directory: directoryReducer,
            me: meReducer,
            nav: navReducer,
        });

        this.store = configureStore({
            reducer: this.reducer,
            middleware: getDefaultMiddleware => getDefaultMiddleware().prepend(
            ),
        });

        this.store.subscribe(() => {
            this.prevState = this.state;
            this.onStateChange(this.state, this.prevState);
        });
    }

    get state(): IControllerState {
        return this.store.getState();
    }

    async auth(sid: string, endpoint: string, roomCode?: string, name?: string): Promise<GerdooAuthToken | undefined> {
        if (!sid) console.error('Cannot auth: No session id provided');
        const headers: any = {
            'Content-Type': 'application/json',
            'gerdoo-client-role': 'player',
        };

        if (sid) {
            headers['gerdoo-client-sid'] = sid;
        }

        const res = await Axios.get(`${endpoint}?roomCode=${roomCode || ''}&name=${name || ''}`, { headers });
        if (res.status = 200) return res.data as GerdooAuthToken;

        return undefined;
    }

    async connect(name?: string, roomCode?: string): Promise<boolean> {
        this.store.dispatch(connectionActions.connecting());

        const authToken = await this.auth(this.sessionId, this.authEndpoint, roomCode, name);
        if (!authToken || authToken.error) {
            this.store.dispatch(connectionActions.authFail({ roomCode, error: authToken?.error }));
            if (authToken.error) {
                this.store.dispatch(navActions.toast({ msg: authToken.error }));
            }
            return false;
        }

        if (this.state.connection.state !== 'auth-success') {
            this.store.dispatch(connectionActions.authSuccess(authToken));
        }

        this.pubsub = new PubNubProvider({ uuid: authToken.psid, publishKey: authToken.publishKey, subscribeKey: authToken.subscribeKey });

        await waitUntil(() => this.state.connection.state === 'auth-success');
        this.store.dispatch(connectionActions.connecting());
        this.pubsub.subscribe(['frontdoor']);

        return this.initPubsub(roomCode);
    }

    private initReconnectTimer() {
        setInterval(() => {
            if (this.state.connection.roomCode && this.lastUpdateTime > 0) {
                const timeSinceLastUpdate = Date.now() - this.lastUpdateTime;
                // reconnect strategy:
                /* after */ const pingT = 5000; /* start pinging the server on every second */
                /* after */ const timeoutT = 8000; /* declare timeout (ui says 'reconnecting') */
                /* after */ const dcT = 15000; /* declare disconnected (ui shows manual button to try rejoin) */

                // ping every second after 3s with no updates
                if (timeSinceLastUpdate > pingT && this.state.connection.state !== 'disconnected') {
                    this.sendCommand({
                        type: 'gerdoo.ping',
                    });
                }

                if (timeSinceLastUpdate > dcT) {
                    this.store.dispatch(connectionActions.disconnected());
                }
                else if (timeSinceLastUpdate > timeoutT) {
                    this.store.dispatch(connectionActions.timeout());
                }
            }
        }, 1000);
    }

    private async initPubsub(attemptedRoomCode?: string): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            this.pubsub.init({
                onConnect: async ({ channels }) => {
                    console.log(`Connected to pubsub.`);

                    const channel = this.state.connection.authToken.channel;
                    if (channel && channels.indexOf(channel) < 0) {
                        await this.pubsub.subscribe([this.state.connection.authToken.channel]);
                        this.pubsub.publish('regplayer', { uuid: this.state.connection.authToken.uuid });
                    }
                },
                onMessage: async (evt) => {
                    const { connection, gameData, gameState } = this.state;
                    const { authToken } = connection;

                    if (evt.channel === authToken.channel && evt.publisher !== authToken.psid) {
                        if (evt.message.type === 'playerregconfirm') {
                            console.log(`Player registered: ${authToken.uuid}`);
                            this.store.dispatch(connectionActions.connected({ roomCode: evt.message.roomCode }));

                            this.initReconnectTimer();
                            resolve(true);
                        }

                        this.lastUpdateTime = Date.now();
                        const update = evt.message as IGerdooPlayerUpdate;
                        if (update && update.type && update.uuid === authToken.uuid) {
                            if (connection.state !== 'connected') {
                                this.store.dispatch(connectionActions.connected({ roomCode: connection.roomCode }));
                            }

                            if (update.type === 'roj.hostLock' && update.lock) {
                                const handle = setTimeout(() => {
                                    if (Date.now() - this.lastUnlockTime > 1000) {
                                        this.store.dispatch(actionPanelActions.lock());
                                    }
                                    clearTimeout(handle);
                                }, 1000);
                                return;
                            } else {
                                this.lastUnlockTime = Date.now();
                                this.store.dispatch(actionPanelActions.unlock());
                            }

                            if (update.type === 'gerdoo.error' && update.seq === this.lastJoinSeq && !this.lastJoinSeq) {
                                this.store.dispatch(navActions.toast({ msg: update.error }));
                            }

                            switch (update.type) {
                                case 'room.refreshp':
                                    this.store.dispatch(roomActions.update({ data: update.room, me: update.me, others: update.others }));
                                    break;
                                case 'room.join':
                                    this.store.dispatch(connectionActions.connected({ roomCode: update.roomCode }));
                                    break;
                                case 'room.leave':
                                    if (update.guestId === authToken.uuid) this.store.dispatch(connectionActions.left());
                                    break;
                                case 'room.kick':
                                    if (update.guestId === authToken.uuid) this.store.dispatch(connectionActions.left());
                                    break;
                                case 'roj.gameData':
                                    this.store.dispatch(gameDataActions.update(update.gameData));
                                    break;
                                case 'roj.gameState':
                                    this.store.dispatch(gameStateActions.update(update.gameState));
                                    break;
                                case 'roj.actionPanel':
                                    this.store.dispatch(actionPanelActions.update(update.actionPanel));
                                    break;
                                case 'roj.inbox':
                                    this.store.dispatch(inboxActions.update({ inbox: update.inbox, gameData, gameState }));
                                    break;
                                case 'roj.directory':
                                    this.store.dispatch(directoryActions.update(update.directory));
                                    break;
                                case 'roj.me':
                                    this.store.dispatch(meActions.update(update.me));
                                    break;
                            }
                        }
                    }
                },
                onPresence: (evt) => {
                    // console.log(evt);
                },
            });
        });
    }

    async leave(): Promise<boolean> {
        const { connection } = this.state;
        if (!connection.roomCode) return;

        this.pubsub.publish(connection.authToken.channel, {
            uuid: connection.authToken.uuid,
            type: 'room.leave',
            service: `rooms`,
            roomCode: connection.roomCode,
        } as IRoomPlayerLeaveCommand);

        this.store.dispatch(connectionActions.left());
        return waitUntil(() => this.state.connection.state !== 'connected');
    }

    sendCommand(cmd: Partial<IGerdooPlayerCommand>) {
        const { connection } = this.state;

        if (connection.roomCode) {
            this.pubsub.publish(connection.authToken.channel, {
                uuid: connection.authToken.uuid,
                roomCode: connection.roomCode,
                ...cmd,
            });
        }
    }

    sendPlayerInput<ACTION extends keyof APInputDataType>(inputKey: string, action: ACTION, data: APInputDataType[ACTION]) {
        this.sendCommand({
            type: 'roj.input',
            service: 'roj',
            inputKey,
            action: action as any,
            data: data as any,
        });
    }
}
