import PubNub, { PubnubConfig } from "pubnub";

import { IPubSubInitArgs, IPubSubProvider } from "gerdoo-api";

export class PubNubProvider implements IPubSubProvider {
    pubnub: PubNub;
    uuid: string;

    constructor (config: PubnubConfig) {
        this.pubnub = new PubNub(config);
        this.uuid = config.uuid;
    }

    async init(args: IPubSubInitArgs) {
        this.pubnub.addListener({
            status: (statusEvent) => {
                if (statusEvent.category === "PNConnectedCategory") {
                    args.onConnect({channels: statusEvent.subscribedChannels});
                }
            },
            message: async (messageEvent) => {
                // console.log(messageEvent.message);
                try {
                    args.onMessage(messageEvent);
                } catch (e) {
                    console.warn(`PubNubProvider.onMessage threw an exception:`);
                    console.log(e);
                }
            },
            presence: (presenceEvent) => {
                // console.log(presenceEvent);
                args.onPresence(presenceEvent);
            }
        });
    }

    async disconnect(): Promise<void> {
        this.pubnub.unsubscribeAll();
        this.pubnub.stop();
    }

    async subscribe(channels: string[]): Promise<void> {
        this.pubnub.subscribe({
            withPresence: true,
            channels,
        });
    }

    async unsubscribe(channels: string[]): Promise<void> {
        this.pubnub.unsubscribe({
            channels,
        });
    }

    async publish(channel: string, message: any): Promise<void> {
        this.pubnub.publish({
            channel,
            message,
        }).catch((e) => {
            console.warn(`PubNubProvider.publish threw an exception:`);
            console.log(e);
        });
    }
}
