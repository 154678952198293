import _ from "lodash";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps, useHistory } from "react-router";
import { IonAvatar, IonBadge, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonPage, IonRow, IonText, IonTitle, IonToggle, IonToolbar } from "@ionic/react";
import { arrowBack, chevronForward, playSkipForward, tvOutline } from "ionicons/icons";

import { reinstatePowerplay, getPowerplayVerb, getPowerplayDescription, extractTerms } from 'roj-common';
import { IControllerState } from "gerdoo-controller-connector";
import { ROJ } from "gerdoo-api";

import { AppContext } from "../apps/ControllerApp";
import { InboxSecretCard, InboxMissionCard } from "./inbox";
import { getRojTermText } from "./terms";
import { RojAvatar, RojImage } from "./RojAvatar";

const InboxMessageCard: React.FC<{ row: ROJ.IInboxRow }> = ({ row }) => {
  const { gameState } = useSelector((state: IControllerState) => state);

  switch (row.type) {
    case 'secretfile': return <InboxSecretCard />;
    case 'round': return <InboxMissionCard round={row.data} phase={gameState.phase} />;
    case 'powerplay': return <PowerplayCard powerplay={row.data} />;
    case 'trial': return <TrialCard trial={row.data} />;
    case 'summary': return <SummaryCard {...row.data} />;

    default:
      return <></>;
  }
}

export const InboxTab: React.FC = () => {
  const { cdn } = useContext(AppContext);
  const { inbox } = useSelector((state: IControllerState) => state);
  const [opened, open] = useState('');

  return <>
    <IonList>
      <div className="ion-padding-top" style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>{_.isEmpty(inbox.rows) && <>No messages yet</>}</div>
      {(inbox.rows || []).map((row, i) => <IonItem key={row.id} color={row.isActive ? 'primary' : ''} routerLink={`/inbox/${row.id}`} onClick={() => open(row.id)}>
        <IonAvatar slot='start'><img style={{ borderRadius: 0 }} src={`${cdn}/game-icons/${row.icon}.png`} /></IonAvatar>
        <IonLabel><h1>{row.h1}</h1><h2>{row.h2}</h2></IonLabel>
        <IonIcon slot='end' icon={chevronForward} />
        {row.badge && <IonBadge color={row.badge.color}>{row.badge.text}</IonBadge>}
      </IonItem>)}
    </IonList>
  </>;
};

interface InboxMessageProps extends RouteComponentProps<{
  id: string;
}> { }

export const InboxMessage: React.FC<InboxMessageProps> = ({ match }) => {
  const { inbox } = useSelector((state: IControllerState) => state);
  const row = (inbox.rows || []).find(r => r.id === match.params.id);
  const lastRow = (inbox.rows || []).find(r => r.isLast);
  const history = useHistory();

  if (!row) return <></>;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonButton routerLink="/inbox"><IonIcon icon={arrowBack} /></IonButton>
          </IonButtons>
          <IonTitle className="ion-text-center">
            {row.h1}
            {/* {row.badge && <IonBadge color={row.badge.color}>{row.badge.text}</IonBadge>} */}
          </IonTitle>
          {/* <IonIcon slot='end' className="ion-margin-horizontal" icon={helpCircle} size='large' /> */}
        <IonButtons slot='end' className="ion-margin">
          { !row.isLast && <IonButton routerLink={`/inbox/${lastRow?.id}`}><IonIcon icon={playSkipForward} /></IonButton> }
          { row.isLast && <IonBadge color='primary'>IN ACTION</IonBadge> }
        </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {InboxMessageCard({ row })}
        <div style={{height: '100px'}}></div>
      </IonContent>
    </IonPage>
  );
};

export function _print(text: string, gameData: ROJ.IControllerGameData, noChips = false) {
  const terms = extractTerms(text, gameData);
  return <>{terms.map((term, j) => term.type === 'nextline' ? <br key={j} /> : [getRojTermText(term, gameData), <span key={`${j}2`} >&nbsp;</span>])}</>;
};

const PowerplayCard: React.FC<{ powerplay: ROJ.IPowerPlay }> = ({ powerplay }) => {
  const { gameData, inbox } = useSelector((state: IControllerState) => state);
  const pp = reinstatePowerplay(powerplay.id, gameData, inbox)!;
  const { actorId, targetId } = pp;
  const actor = gameData.players[actorId];
  const target = gameData.players[targetId!];

  return <IonRow>
    <IonCol size="12" className="ion-text-center">
      <IonText>A Rogue Loyalist gets to</IonText><br /><br />
      <IonText style={{ backgroundColor: 'yellow' }}><i><b>{getPowerplayDescription(pp.type)}</b></i></IonText>
    </IonCol>
    <IonCol size="12" className="ion-margin-vertical ion-text-center">
      <div style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'space-evenly' }}>
        <RojAvatar caption={actor?.name || 'A Rogue Loyalist'} img={<RojImage type="player" id={actorId} />} redacted={false} />
        <RojAvatar caption={getPowerplayVerb(pp.type)} noFrame={true} img={<RojImage type="power" id={pp.type} />} redacted={false} />
        <RojAvatar caption={target?.name || 'someone'} img={<RojImage type="player" id={targetId} />} />
      </div>
    </IonCol>
    <IonCol size="12">
      <PowerplayStatusCard powerplay={pp} />
    </IonCol>
  </IonRow>;
}

const PowerplayStatusCard: React.FC<{ powerplay: ROJ.IPowerPlay }> = ({ powerplay }) => {
  const { me, gameData, inbox } = useSelector((state: IControllerState) => state);
  const pp = reinstatePowerplay(powerplay.id, gameData, inbox);
  const { actorId, targetId, result } = pp!;
  
  let title, subtitle;
  let important = false;

  switch (powerplay.type) {
    case 'confide':
      if (!powerplay.isFinished) {
        title = actorId === me.pid ? `You gone rogue!` : `Who's gone rogue?`;
        subtitle = actorId === me.pid ? `You get to confide in someone, or skip.` : `It's one of the Loyalists, but it can't be the Agent.`;
      } else {
        if (actorId === me.pid) {
          title = (!targetId || targetId === 'skip') ? `You skipped it` : `You confided in [player.${targetId}]`;
          subtitle = (!targetId || targetId === 'skip') ? `Nothing to take away from this.` : `[player.${targetId}] now knows you're a non-Agent Loyalist.`;
          important = !!targetId;
        } else if (targetId === me.pid) {
          if (!actorId) {
            console.error(`confide target should know the actor`);
          }
          const actor = actorId ? `[player.${actorId}]` : `someone`;
          title = `${actor} confided in you`;
          subtitle = `${actor} must be a non-Agent Loyalist.`;
          important = true;
        } else {
          title = `It's done!`;
          subtitle = `or maybe skipped? You don't really know.`;
        }
      }
      break;

    case 'wiretap':
      if (!powerplay.isFinished) {
        title = actorId === me.pid ? `You gone rogue!` : `Who's gone rogue?`;
        subtitle = actorId === me.pid ? `You get to wiretap someone, or skip.` : `It's one of the Loyalists, but it can't be the Agent.`;
      } else {
        if (actorId === me.pid) {
          title = (!targetId || targetId === 'skip') ? `You skipped it` : result ? `You wiretapped [player.${targetId}]` : ``;
          subtitle = (!targetId || targetId === 'skip') ?  `Nothing to take away from this.` : (result || '');
          important = !!(targetId && result);
        } else if (targetId === me.pid) {
          const actor = actorId ? `[player.${actorId}]` : `Someone`;
          title = `${actor} wiretapped you`;
          subtitle = `They now know ${result}`;
          important = true;
        } else if (targetId === 'leakers') {
          const actor = actorId ? `[player.${actorId}]` : `Someone`;
          title = `${actor} wiretapped another Leaker`;
          subtitle = `They now know if their target has ever leaked`;
          important = true;
        } else {
          title = `It's done!`;
          subtitle = `or maybe skipped? You don't really know.`;
        }
      }
      break;
  }

  return <>
    <IonCard color={'light'}>      
      <IonCardHeader>
        {title && <IonCardTitle style={{display:'flex', alignItems:'center'}}>{important && <IonBadge color={'danger'}>!</IonBadge>}&nbsp;{_print(title, gameData)}</IonCardTitle>}
        {subtitle && <IonCardSubtitle>{_print(subtitle, gameData)}</IonCardSubtitle>}
      </IonCardHeader>
    </IonCard>
  </>
}

const TrialCard: React.FC<{ trial: ROJ.ITrial }> = ({ trial }) => {
  const { gameData, gameState } = useSelector((state: IControllerState) => state);
  const testifier = _.values(gameData.players).find(p => p.id === gameState.trial?.testifierId);
  const agent = _.values(gameData.players).find(p => p.id === gameState.trial?.agentId);
  const testifyTarget = _.values(gameData.players).find(p => p.id === gameState.trial?.targetId);
  const testifyHit = gameState.trial?.confirmed && gameState.trial.agentId === gameState.trial.targetId;

  return <IonRow>
    <IonCol size="12">
      <IonText><i><b>{'Leakers go to court. Testifier gets a chance to expose the Agent and win it for Leakers.'}</b></i></IonText>
    </IonCol>
    <IonCol size="12" className="ion-margin-vertical ion-text-center">
      <div style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'space-evenly' }}>
        <RojAvatar caption={testifier?.name || 'someone'} img={<RojImage type="character" id={testifier?.character} />} />
        <div style={{ display: 'flex', alignItems: 'end' }} className='ion-padding-bottom'>testifies against</div>
        <RojAvatar caption={testifyTarget?.name || '???'} img={<RojImage type="character" id={testifier?.character} />} />
      </div>
    </IonCol>

    {gameState.trial?.confirmed && <IonCol size="12" className="ion-text-center">
      <IonCard color={testifyHit ? 'danger' : 'tertiary'}>
        <IonCardHeader>
          <IonCardTitle>{testifyHit ? 'Agent Exposed!' : 'Agent remains undercover!'}</IonCardTitle>
          <IonCardSubtitle>{testifyHit ? 'Puma is indicted and Leakers get to walk free.' : 'Leakers are indicted and Puma remains in power.'}</IonCardSubtitle>
        </IonCardHeader>
      </IonCard>
    </IonCol>}

    {gameState.trial?.confirmed && !testifyHit && <IonCol size="12" className="ion-text-center">
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>The Real Agent</IonCardTitle>
        </IonCardHeader>
        <IonCardContent className="ion-padding">
          <RojAvatar caption={agent?.name || '???'} img={<RojImage type="character" id={agent?.character} />} />
        </IonCardContent>
      </IonCard>
    </IonCol>}
  </IonRow>;
}

const SummaryCard: React.FC<{ winner: ROJ.Team, leakerIds: string[] }> = ({ winner, leakerIds }) => {
  const { gameData, gameState } = useSelector((state: IControllerState) => state);
  const leakers = _.values(gameData.players).filter(p => leakerIds.indexOf(p.id) >= 0);
  const loyalists = _.values(gameData.players).filter(p => leakerIds.indexOf(p.id) < 0);
  const testifyHit = gameState.trial?.confirmed && gameState.trial.agentId === gameState.trial.targetId;

  return <IonRow>
    <IonCol size="12" className="ion-text-center">
      <IonCard color={winner === 'leakers' ? 'danger' : 'tertiary'}>
        <IonCardHeader>
          <IonCardTitle>{winner === 'leakers' ? 'Leakers Win!' : 'Loyalists Win!'}</IonCardTitle>
          <IonCardSubtitle>{winner === 'leakers' ? (testifyHit ? 'They exposed the Agent in court.' : 'They infiltrated 3 missions.') : 'They cover up 3 missions. The Agent remains undercover.'}</IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent className="ion-padding">
        </IonCardContent>
      </IonCard>
    </IonCol>
    <IonCol size="12">
      <div className="ion-margin-top" style={{ display: 'flex', justifyContent: 'center' }}>
        {(winner === 'leakers' ? leakers : loyalists).map(p => <RojAvatar key={p.id} caption={p.name || '???'} img={<RojImage type="character" id={p.character} />} />)}
      </div>
    </IonCol>
    <IonCol size="12" className="ion-text-center ion-margin-top">
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <IonIcon icon={tvOutline} style={{ fontSize: 120 }} />
        <IonLabel>Look up at the screen for the full summary.</IonLabel>
      </div>
    </IonCol>
  </IonRow>;
}
