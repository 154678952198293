import _ from "lodash";
import * as WebSocket from 'ws';
import { IPubSubInitArgs, IPubSubProvider } from "gerdoo-api";

// ILocalWSMessage = JSON.parse(WebSocket.MessageEvent.data)
export interface ILocalWSMessage {
    type: 'subscribe' | 'unsubscribe' | 'publish' | 'message';
    publisher: string;
    channels: string[];
    data?: any;
}

export class LocalWSServerProvider implements IPubSubProvider {
    uuid: string;
    sockets: WebSocket[] = [];
    serverSubs: string[] = [];
    clientSubs: {[uuid: string]: string[]} = {};
    clientSockets: {[uuid: string]: WebSocket.WebSocket} = {};

    constructor(
        private readonly serverId: string,
        private readonly wsServer: WebSocket.Server
    ) {
        this.uuid = serverId;
    }

    async init(args: IPubSubInitArgs): Promise<void> {
        this.wsServer.on('error', err => {
            console.error('Websocket Server Error:');
            console.error(err);
            console.error(err?.stack);
        });

        this.wsServer.on('connection', (socket: WebSocket) => {
            socket.addEventListener('message', async (event) => {
                try {
                    const wsMsg: ILocalWSMessage = JSON.parse(event.data as string);
                    for (const channel of wsMsg.channels) {
                        if (wsMsg.type === 'subscribe') {
                            this.clientSockets[channel] = event.target;
                            this.clientSubs[wsMsg.publisher] = wsMsg.channels;
                        } else if (wsMsg.type === 'unsubscribe') {
                            this.clientSubs[wsMsg.publisher] = this.clientSubs[wsMsg.publisher].filter(sub => wsMsg.channels.indexOf(sub) < 0);
                        } else if (wsMsg.type === 'publish') {
                            if (this.serverSubs.indexOf(channel) >= 0) {
                                args.onMessage({
                                    channel,
                                    message: wsMsg.data,
                                    publisher: wsMsg.publisher,
                                    timetoken: `${Date.now()}`,
                                });
                            }

                            (this.clientSubs[channel] || []).map(ch => this.publish(ch, wsMsg.data, wsMsg.publisher));
                        }
                    }
                } catch (err) {
                    console.error(`LocalWSServerProvider: ${event.data}`);
                    console.log(err);
                    // console.log(err?.stack);
                }
            });

            this.sockets.push(socket);
        });

        args.onConnect({channels: this.serverSubs});
    }

    async subscribe(channels: string[]): Promise<void> {
        channels.forEach(channel => {
            if (this.serverSubs.indexOf(channel) < 0) {
                this.serverSubs.push(channel);
            }
        });
    }

    async unsubscribe(channels: string[]): Promise<void> {
        this.serverSubs = this.serverSubs.filter(sub => channels.indexOf(sub) < 0);
    }

    async publish(channel: string, data: any, publisher?: string): Promise<void> {
        const targetClient = this.clientSockets[channel];
        const wsMsg: ILocalWSMessage = {
            channels: [channel],
            publisher: publisher || this.serverId,
            type: 'message',
            data,
        };

        if (targetClient) {
            // console.log(`[${Date.now().toString().slice(-2)}] publish: ${data.type} @${channel}`);
            targetClient.send(JSON.stringify(wsMsg));
        } else {
            // console.warn(`Skipped publish to channel with no subs: [${channel}]`);
        }
    }

    async disconnect(): Promise<void> {
        this.sockets.forEach(s => s.close());
    }
}
