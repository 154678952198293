import _ from "lodash";
import { flash, chevronForward, close, logOut } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IonButton, IonButtons, IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonModal, IonPage, IonTitle, IonToolbar, useIonModal } from "@ionic/react";

import { IControllerState } from "gerdoo-controller-connector";
import { getInputDescription } from 'roj-common';

import { ActionPanel } from "./ActionPanel";
import { AppContext } from "../apps/ControllerApp";

// const LookUpModal: React.FC = () => <>
//     <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 150, fontSize: 40, fontWeight: 'bold' }}>
//         <div style={{ fontSize: 100 }}><IonIcon icon={tvOutline} /></div>
//         <div style={{ display: 'flex', justifyContent: 'center' }}>L<span style={{ fontSize: 30 }}>&#128065;&#65039;&#128065;&#65039;</span>K UP!</div>
//     </div></>;

export const ActionOverlay: React.FC = () => {
    const { connector } = useContext(AppContext);
    const { actionPanel, gameState, me } = useSelector((state: IControllerState) => state);
    const actionKeys = _.keys(actionPanel.input).filter(k => k !== 'concede');
    const hasAction = !_.isEmpty(actionKeys);
    const okOnly = actionKeys.length === 1 && actionKeys[0] === 'ready';
    const forceOpen = gameState.phase === 'setup' && actionKeys.length > 0;

    const modal = <IonPage>
        <IonHeader>
            <IonToolbar>
                <IonTitle className="ion-text-center">{ getInputDescription(gameState.phase) }</IonTitle>
                { !forceOpen && <IonButtons slot='end'><IonButton onClick={() => dismiss()}><IonIcon icon={close} /></IonButton></IonButtons> }
            </IonToolbar>
        </IonHeader>
        <IonContent className='ion-padding'>
        {(forceOpen || !actionPanel.locked) && hasAction && <ActionPanel />}
        {/* {actionPanel.locked && <LookUpModal />} */}
    </IonContent></IonPage>;

    const [present, dismiss] = useIonModal(modal, {
        // onDidDismiss: (data: string, role: string) => { dismiss(data, role); setOpen(false) },
        // onDidPresent: () => setOpen(true),
    });

    useEffect(() => {
        if (!hasAction || okOnly) dismiss();
        else if (forceOpen) present();
    }, [hasAction, okOnly, forceOpen]);

    return <IonFab vertical="bottom" horizontal="center" slot="fixed" style={{marginBottom: 20}}>
        {!hasAction && gameState.phase === 'summary' && <IonFabButton className='actionButton' onClick={() => connector.leave()}><IonIcon icon={logOut} /></IonFabButton>}
        {!okOnly && hasAction && <IonFabButton className='actionButton' onClick={() => present()}><IonIcon icon={flash} /></IonFabButton>}
        {okOnly && <IonFabButton className='actionButton' onClick={() => connector.sendPlayerInput('ready', 'tap.done', {})}><IonIcon icon={chevronForward} /></IonFabButton>}
        {/* { !hasAction && <IonFabButton color='medium' onClick={() => present()}><IonIcon icon={tvOutline} /></IonFabButton> } */}
    </IonFab>
};
