import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { IonApp, IonButton, IonContent, IonModal, IonPage, IonSpinner, IonText, setupIonicReact, useIonToast } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import { ControllerConnector } from 'gerdoo-controller-connector';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import '../theme/variables.css';
import { ControllerLobby } from '../components/ControllerLobby';
import { ControllerComponent } from '../components/ControllerComponent';
import { JoinForm } from '../components/JoinForm2';

import './ControllerApp.scss';

setupIonicReact();

if (!process.env.REACT_APP_CDN) {
  console.error('Env var required: REACT_APP_CDN');
}

const context = {
  cdn: '',
  connector: undefined!,
};
export const AppContext = React.createContext<{ cdn: string, connector: ControllerConnector }>(context);

export const ControllerApp: React.FC<{ connector: ControllerConnector }> = ({ connector }) => {
  const {
    room,
    connection,
    nav,
  } = connector?.state || {};

  const [isLoading, setIsLoading] = useState(false);
  const [toast, dismiss] = useIonToast();

  useEffect(() => {
    if (connection.state === 'auth-pending' || connection.state === 'connecting') {
      const handle = setTimeout(() => {
        setIsLoading(true);
        clearTimeout(handle);
      }, 10);
    } else {
      const handle = setTimeout(() => {
        setIsLoading(false);
        clearTimeout(handle);
      }, 500);
    }
  }, [connection.state]);

  useEffect(() => {
    if (nav.toast?.msg) {
      toast(nav.toast.msg);
      setTimeout(() => dismiss(), 1000);
    }
  }, [nav.toast]);

  return (
    <Provider store={connector.store}>
      <AppContext.Provider value={{ cdn: process.env.REACT_APP_CDN!, connector }}>
        <IonApp>
          <IonModal isOpen={connection.state === 'timeout' || connection.state === 'disconnected'}>
            <IonContent>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', fontSize: 20 }}>
                { connection.state === 'timeout' && <>
                  <p>Connection interrupted.</p>
                  <p>Reconnecting...</p>
                </> }
                { connection.state === 'disconnected' && <>
                  <p>We tried, but couldn't reconnect.</p>
                  <p>Check your connection and retry.</p>
                  <p><IonButton 
                    onClick={() => connector.connect(room.me.name, room.code)}
                  >Re-connect</IonButton></p>
                </> }
              </div>
            </IonContent>
          </IonModal>

          { isLoading && <div style={{position: 'fixed', left: '50%', top: '50%', zIndex: 10000, transform: 'translate(-50%,-50%)'}}>
              <IonSpinner name='circular' color='primary' />
          </div>}

          <IonReactRouter>
            {!connection.roomCode && <IonPage>
              <IonContent fullscreen slot='fixed'>
                <JoinForm join={(name, code) => {
                  connector?.connect(name, code);
                }} />
              </IonContent>
            </IonPage>}

            {connection.roomCode && room.state === 'lobby' && <IonPage id={'lobby' + connector.sessionId}>
              <IonContent>
                <ControllerLobby />
              </IonContent>
            </IonPage>}

            {connection.roomCode && room.state === 'ingame' && <ControllerComponent />}

          </IonReactRouter>
        </IonApp>
      </AppContext.Provider>
    </Provider>
  );
};
