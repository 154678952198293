import _ from "lodash";
import WebSocket from "isomorphic-ws";

import { IPubSubInitArgs, IPubSubProvider } from "gerdoo-api";
import { ILocalWSMessage } from "./LocalWSServerProvider";

export class LocalWSClientrProvider implements IPubSubProvider {
    uuid: string;
    socket: WebSocket;

    constructor(
        private readonly hostId: string,
        private readonly serverUrl: string,
    ) {
        this.uuid = hostId;
    }

    async init(args: IPubSubInitArgs): Promise<void> {
        return new Promise<void>(resolve => {
            this.socket = new WebSocket(this.serverUrl);
            this.socket.onopen = () => {
                args.onConnect({channels: []});
                resolve();
            };
            
            this.socket.onmessage = (evt) => {
                const wsMsg: ILocalWSMessage = JSON.parse(evt.data as string);
                if (wsMsg.type === 'message') {
                    args.onMessage({
                        channel: wsMsg.channels[0],
                        publisher: wsMsg.publisher,
                        message: wsMsg.data,
                        timetoken: `${Date.now()}`,
                    });
                }
            };
        });
    }

    async subscribe(channels: string[]): Promise<void> {
        if (!this.socket) return;
        const wsMsg: ILocalWSMessage = {
            channels: channels,
            publisher: this.hostId,
            type: 'subscribe',
        }
        this.socket.send(JSON.stringify(wsMsg));
    }

    async unsubscribe(channels: string[]): Promise<void> {
        const wsMsg: ILocalWSMessage = {
            channels: channels,
            publisher: this.hostId,
            type: 'unsubscribe',
        }
        this.socket.send(JSON.stringify(wsMsg));
    }

    async publish(channel: string, data: any): Promise<void> {
        const wsMsg: ILocalWSMessage = {
            channels: [channel],
            publisher: this.hostId,
            type: 'publish',
            data,
        }
        this.socket.send(JSON.stringify(wsMsg));
    }

    async disconnect(): Promise<void> {
        this.socket.close();
    }
}
